import React, { useState, useEffect, useRef, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import FormInput from '../../../Utilities/FormInput/FormInput';
import PrimaryButton from '../../../Utilities/PrimaryButton/PrimaryButton';

import NavigationContext from '../../../../Context/NavigationContext';

import './RegisterForm.scss';
import ContactInfo from '../../../../Data/Contact';

import UtilityFunction from '../../../../Util/Util';
import authAPI from '../../../../Data/Auth';
import { useNavigate } from 'react-router-dom';
import CustomToast from '../../../Utilities/Toast/Toast';
import useAnalyticsEventTracker from '../../../../GoogleAnalytics/useAnalyticsEventTracker';

const { getFormattedDatetime, base64encode, responseFormat } = UtilityFunction;

const GaTracker = (label) => {
    const gaEventCategory = 'Sign In Page';

    const gaEventTracker = useAnalyticsEventTracker(gaEventCategory);

    gaEventTracker("Register", label);
};

function RegisterForm(props) {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        /* 
            1. Transaction Code (Order No.)
            2. Transfer Time (Tgl Transfer)
            3. Sender (Nama Pengirim)
            4. Account No. (No Rekening)
            5. Bank Name (Nama Bank)
            6. Proof of Transfer (Bukti Transfer)
        */
        fullname: {
            label: "Fullname",
            ref: useRef(''),
            value: '',
            width: 12, // x < 12
            type: "text",
            required: true,
            // numeric: true,
            placeholder: 'John Doe'
        },
        email: {
            label: "Email",
            ref: useRef(''),
            value: '',
            width: 12, // x < 12
            type: "text",
            required: true,
            // numeric: true,
            placeholder: 'Enter Your Email'
        },
        phoneNo: {
            label: "No. HP",
            ref: useRef(''),
            value: '',
            width: 12, // x < 12
            type: "text",
            prefix: "+62",
            required: true,
            placeholder: 'No. HP / Whatsapp'
        },
        password: {
            label: "Password",
            ref: useRef(''),
            value: '',
            width: 12, // x < 12
            type: "password",
            required: true,
            // numeric: true,
            placeholder: 'Enter your password'
        },
        confirmPassword: {
            label: "Confirm Password",
            ref: useRef(''),
            value: '',
            width: 12, // x < 12
            type: "password",
            required: true,
            // numeric: true,
            placeholder: 'Enter your confirmed password'
        },
    });
    const navigation = useContext(NavigationContext);

    const [toastContent, setToastContent] = useState({
        title: '',
        content: '',
        status: null,
        show: false
    });

    const closeToast = () => {
        setToastContent({
            ...toastContent,
            show: false
        });
    }

    const openToast = (isSuccess, message) => {
        const content = toastContent;

        content.title = isSuccess ? 'Success' : 'Failed';
        content.content = message;
        content.status = isSuccess;
        content.show = true;

        setToastContent({
            ...content
        });
    }

    useEffect( () => {
        
    }, []);

    const formUpdate = (name, value) => {
        switch(name) {
            // case 'bank_account_no':
            //     if(value.match(/^[0-9]+$/) !== null) {
            //         formData[name].hasError = false;
            //         formData[name].errorMessage = '';
            //     } else {
            //         formData[name].hasError = true;
            //         formData[name].errorMessage = 'Invalid Bank Account No.';
            //     }

            //     setFormData({
            //         ...formData
            //     });
            //     break;
        }
    }

    const submitForm = () => {
        // console.log(Object.keys(formData).map((name, index) => {
        //     let item = formData[name];

        //     return [name, item.ref.current.value];
        // }));
        let requestData = {};

        for(let key in formData) {
            let item = formData[key];
            let value = item.ref.current.value;
            value = (value !== undefined && value !== null) ? value : '';

            requestData[key] = value;
        }

        (
            async() => {
                const response = await authAPI.register(requestData.fullname, requestData.email, requestData.phoneNo, requestData.password, requestData.confirmPassword);
                // const response = responseFormat(-1, "Failed to authenticate!", [
                //     { field: "Email", message: "Email is required!" },
                //     { field: "Password", message: "Password is required!" },
                // ]);

                if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                    // navigate(`/?token=${ response.data.token }`);
                    // window.location.href = `/?token=${ response.data.token }`;
                    GaTracker("Success");
                    props.onSuccess(response.data);
                } else {
                    GaTracker("Failed");
                    for(let index in response.data) {
                        let item = response.data[index];
                        let field = item.hasOwnProperty('field') ? item.field : '';
                        let message = item.hasOwnProperty('message') ? item.message : '';

                        if(field !== '' && formData.hasOwnProperty(field)) {
                            formData[field].hasError = true;
                            formData[field].errorMessage = message;
                        }
                    }

                    setFormData({
                        ...formData
                    });
                }

                openToast(
                    response.hasOwnProperty('errorCode') && response.errorCode === 0,
                    response.message
                );

            }
        )();
    };

    return (
        <>
            <CustomToast show={toastContent.show} 
                onClose={closeToast} 
                title={toastContent.title} 
                status={toastContent.status}
                customClass="account-modify-toast"
            >
                <div className="content">
                    { toastContent.content }
                </div>
            </CustomToast>
            <div className='register-form-container'>
                <Row className={`register-form`}>
                    
                    {
                        Object.keys(formData).map( (key, index) => {
                            let item = formData[key];

                            return <FormInput 
                                        key={index} 
                                        attribute={item}
                                        options={item.hasOwnProperty('options') ? item.options : []}
                                        name={key} 
                                        onChange={ (name, value) => formUpdate(name, value) } 
                                    />
                        } )
                    }
                </Row>
                <PrimaryButton size="sm" customClass="btn-register-form" onClick={() => submitForm()}>
                    Sign Up
                </PrimaryButton>
            </div>
        </>
    );
}

export default RegisterForm;