import React, {Component} from 'react';
import './Homepage.scss';

import Header from '../../Component/PagesComponent/Homepage/Header/Header';
import Brands from '../../Component/PagesComponent/Homepage/Brands/Brands';
import About from '../../Component/PagesComponent/Homepage/About/About';
import Values from '../../Component/PagesComponent/Homepage/Values/Values';
import PackagingCatalog from '../../Component/PagesComponent/Homepage/PackagingCatalog/PackagingCatalog';
import Testimony from '../../Component/PagesComponent/Homepage/Testimony/Testimony';
import OrderInstruction from '../../Component/PagesComponent/Homepage/OrderInstruction/OrderInstruction';
class Homepage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayValue: false
        }
    }
    
    componentDidMount() {
        window.addEventListener('resize', function(event){});
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        console.log("Mounted!");

        setTimeout(() => {
            this.setState({
                ...this.state,
                displayValue: true,
            });
        }, 5);
    }

    componentDidUpdate() {
        // window.scrollTo({
        //     top: 0,
        //     behavior: 'smooth'
        // });
    }
    
    componentWillUnmount() {
        this.setState = (state,callback)=>{
            return;
        };

        window.removeEventListener('resize', () => {});
    }

    render() {
        return (
            <div className="homepage">
                <Header />
                <Brands />
                <About />
                <PackagingCatalog 
                    title={"Sudah Punya Design Sendiri?"}
                    subtitle={"Custom Sekarang! Kalian bisa pilih sendiri produknya 👇"}
                    isReadyStock={0}
                />
                <PackagingCatalog 
                    elementId={"ready-stock"}
                    title={"Beli Produk Ready Stock Kami (Tanpa Custom)"}
                    subtitle={"Semua produk kami siap kirim ke seluruh Indonesia! 👇"}
                    isReadyStock={1}
                />
                <Testimony />
                <OrderInstruction />
                {
                    this.state.displayValue ? (
                        <Values />
                    ) : ''
                }
            </div>
        );
    }
}

export default Homepage;