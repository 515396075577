import React, {Component, createRef} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import NavigationBar from './Component/Include/Navbar/Navbar';
import AppContext from './Context/AppContext';
import Footer from './Component/Include/Footer/Footer';

// import Cursor from './Component/Utilities/Cursor/Cursor';
// import CursorContext from './Context/CursorContext';

import Routes from './Routing/Routing';
import Init from './Init/Init';
import WhatsappButton from './Component/Utilities/WhatsappButton/WhatsappButton';
import SNI from './Component/Utilities/MetaTag/MetaTag';

/* Analytics */
import MetaPixel from './Component/Utilities/MetaPixel/MetaPixel';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';

if(process.env.REACT_APP_ENV === 'production') {
  /* GA */
  // const TRACKING_ID = "UA-250517852-1"; // OUR_TRACKING_ID
  // ReactGA.initialize(TRACKING_ID);
  
  /* Tag Manager */
  const tagManagerConfig = {
      gtmId: 'GTM-KWGHCF4J',
      // dataLayerName: 'PageDataLayer'
  }
  TagManager.initialize(tagManagerConfig);
  
  /* GA 4 */
  const MEASUREMENT_ID = 'G-9MQCMWK6Q1';
  ReactGA.initialize(MEASUREMENT_ID);
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentRoute: window.location.pathname,
      appRef: createRef()
    }
  };

  static contextType = AppContext;

  componentDidMount() {
    let { pathname, search, hash } = window.location;

    // ReactGA.send({
    //   hitType: 'pageview',
    //   path: pathname + hash + search,
    //   title: pathname
    // });
  }

  hideNav(currentPath) {
    let routes = Routes();

    let hasNavRoutes = routes.filter( (value, key) => {
      return !value.showNav;
    } ).map( (item) => {
      return item.path;
    } );

    return hasNavRoutes.includes(currentPath);
  }

  hideFooter(currentPath) {
    let routes = Routes();

    let hasNavRoutes = routes.filter( (value, key) => {
      return !value.showFooter;
    } ).map( (item) => {
      return item.path;
    } );

    return hasNavRoutes.includes(currentPath);
  }

  render() {
    const routes = Routes();
    const state = this.state;

    // let hasFooterRoutes = routes.filter( (value, key) => {
    //   return value.showFooter;
    // } ).map( (item) => {
    //   return item.path;
    // } );

    const handleNavClick = (path) => {
      let state = this.state;
      state.currentRoute = path;

      this.setState(state);
    }

    console.log('Render App');

    return(
      <AppContext.Provider value={ {
        refresh: () => this.forceUpdate()
      } }>
        <div ref={state.appRef} className={"App"}>
          {/* <SNI /> */}
          {/* <MetaPixel /> */}
          <WhatsappButton isFixed={true} />
          <NavigationBar hide={this.hideNav(state.currentRoute)} navList={routes} onChoose={(path) => handleNavClick(path)} />
          <Footer hide={ this.hideFooter(state.currentRoute) } />
        </div>
      </AppContext.Provider>
    );
  }
}

export default App;
